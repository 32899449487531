<template>
  <div class="vat-invoice">
    <KTCodePreview v-bind:title="title">
      <template v-slot:toolbar>
        <div class="row">
          <b-dropdown
            size="sm"
            right
          >
            <template slot="button-content">
              <i
                class="fas fa-cog"
                style="font-size: 1rem"
              ></i>
              <span class="font-weight-bolder">Thao tác</span>
            </template>
            <b-dropdown-item @click="downloadExcel">
              <span>
                <i class="far fa-file-excel icon-size pr-1"></i>
                Xuất Excel</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template v-slot:preview>
        <VatInvoiceHeader
          :apiParams="apiParams"
          @getParamFilters="onFilter()"
        />
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col></b-col>
              <b-col>
                <b-pagination-nav
                  class="custom-pagination"
                  v-if="numberOfPage >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="getBillVatInvoices"
                  align="right"
                  pills
                  size="sm"
                  first-class="page-item-first btn btn-icon btn-sm m-1"
                  prev-class="page-item-prev btn btn-icon btn-sm m-1"
                  next-class="page-item-next btn btn-icon btn-sm m-1"
                  last-class="page-item-last btn btn-icon btn-sm m-1"
                  page-class="btn btn-icon btn-sm border-0 m-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-double-arrow-back icon-xs"></i>
                    </span>
                  </template>
                  <template v-slot:prev-text>
                    <i class="ki ki-arrow-back icon-xs"></i>
                  </template>
                  <template v-slot:next-text>
                    <i class="ki ki-arrow-next icon-xs"></i>
                  </template>
                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <table class="table table-bordered-list table-hover col-md-12">
              <thead>
                <tr>
                  <th class="text-center tdCode">ID</th>
                  <th class="text-center tdCustomer">Khách hàng</th>
                  <th class="text-center tdProductName">Sản phẩm</th>
                  <th class="text-center tdQuantity">SL</th>
                  <th class="text-center tdProductPrice">Giá tiền</th>
                  <th class="text-center tdDiscountItem">C.Khấu</th>
                  <th class="text-center tdDiscountItem">C.khấu phân bổ</th>
                  <th class="text-center tdQuantity">Thuế suất</th>
                  <th class="text-center tdTotalAmount">Tổng tiền</th>
                  <th class="text-center tdNote">Ghi chú</th>
                  <th class="text-center tdNote">Trạng thái</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template v-if='onLoading'>
                  <tr>
                    <td
                      colspan="12"
                      class="text-center"
                    >
                      <b-spinner
                        variant="primary"
                        class='my-4'
                      ></b-spinner>
                    </td>
                  </tr>
                </template>
                <template v-for="(bill, index) in vatInvoices">
                  <tr
                    :key="bill.id"
                    @mouseover="hoverRow"
                    @mouseleave="outRow"
                    :data="`row_${bill.billNumber}`"
                  >
                    <td
                      colspan="10"
                      style="
                        padding: 5px !important;
                        border-bottom: none !important;
                      "
                    >
                      <div class="d-flex">
                        <div>
                          <b> {{ bill.storeName }}</b>
                          <span
                            class="ml-1"
                            v-if="bill.companyName"
                            style="font-size: 10px"
                          >( {{ bill.companyName }} )</span>
                        </div>
                      </div>
                      <div
                        class="d-flex"
                        style="font-size: 0.87rem"
                      >
                        <span class="mr-4">Ngày tạo hóa đơn bán hàng:
                          <span class="text-primary">
                            {{ bill.createdAt }}</span></span>

                        <span class="mr-4 font-weight-bolder">Ngày cập nhật:
                          <span class="text-primary">
                            {{ bill.updatedAt }}</span></span>

                        <span class="mr-4">
                          <span class="mr-1">Người tạo:</span>
                          <span v-if="bill.billVatCreatedByName">
                            {{ bill.billVatCreatedByName }}
                          </span>
                          <span
                            v-else
                            class="text-danger"
                          >
                            Chưa được gán
                          </span>
                        </span>

                        <span
                          class="ml-4"
                          v-if="isShownWarningExpiredBillVat(bill) && bill.isBillVatExpired"
                        >
                          <span class="mr-1"> <i
                              v-b-tooltip.hover
                              title="Đã quá thời hạn quy định, lưu ý không thể xuất hóa đơn"
                              class="fa fa-exclamation-triangle icon-color text-danger"
                            ></i></span>
                          <span>
                            {{ bill.differenceDays }} ngày, {{ bill.differenceHours }} giờ , {{ bill.differenceMinutes }} phút
                          </span>
                        </span>
                      </div>
                    </td>
                    <td
                      style="border-bottom: none !important"
                      colspan="3"
                    ></td>
                  </tr>
                  <template v-for="(billItem, j) in bill.details">
                    <tr
                      :key="`${index + j}_${billItem.id}`"
                      @mouseover="hoverBill"
                      @mouseleave="outBill"
                      :data="`row_${bill.billNumber}`"
                    >
                      <td
                        :rowspan="bill.details.length"
                        v-if="j === 0"
                        class="tdTextAlign tdCode"
                      >
                        <div
                          class="orderCode text-center"
                          style="cursor: pointer"
                        >
                          <p
                            class="font-weight-bold text-primary"
                            @click="navigateToDetailBill(bill.billNumber)"
                          >
                            {{ bill.billNumber }}
                          </p>
                          <p
                            class="font-weight-bold text-warning"
                            @click="showModalCreateVatInvoice(bill, 'update')"
                          >
                            {{ bill.id }}
                          </p>
                        </div>
                      </td>
                      <td
                        :rowspan="bill.details.length"
                        v-if="j === 0"
                        class="tdTextAlign tdCustomer wrapword"
                      >
                        <span class="font-size-xs text-primary font-weight-bolder">({{ bill.billVatTypeName }})</span>
                        <p
                          v-if="bill.taxCode"
                          class="orderCode"
                          style="
                            margin-bottom: 0;
                            cursor: pointer;
                            font-weight: 550;
                          "
                        >
                          {{ bill.billVatType === 1 ? 'MST:' : 'SĐT:' }}
                          <span class="font-weight-bold text-warning">{{
                            bill.taxCode
                          }}</span>
                        </p>
                        <p
                          class="orderCode"
                          style="
                            margin-bottom: 0;
                            cursor: pointer;
                            font-weight: 550;
                          "
                        >
                          {{ bill.billVatName }}
                        </p>
                        <p
                          class="orderCode"
                          @click="editUser(bill)"
                          style="margin-bottom: 0; cursor: pointer"
                        >
                          <span
                            class="font-weight-bold"
                            v-if="bill.billVatAddress"
                          >ĐC:</span>
                          {{ bill.billVatAddress }}
                        </p>
                        <p :class="[
                            `dashed mt-2 pt-2 ${
                              bill.email || 'font-weight-bolder text-danger'
                            }`,
                          ]">
                          {{ bill.email || 'Thiếu thông tin email' }}
                        </p>
                      </td>
                      <td class="tdTextAlign tdProductName wrapword">
                        <span class="font-weight-bold">{{
                          billItem.productName
                        }}</span>
                        <p :class="[
                            `font-size-sm ${
                              billItem.productNameVat ||
                              'font-weight-bolder text-danger'
                            }`,
                          ]">
                          <span class="font-weight-bold text-warning">Tên VAT: </span>{{
                            billItem.productNameVat || 'Thiếu tên sản phẩm VAT '
                          }}
                        </p>
                      </td>
                      <td class="tdTextAlignPrice tdQuantity">
                        {{ billItem.quantity }}
                      </td>
                      <td class="tdTextAlignPrice tdProductPrice font-weight-bold">
                        {{ convertPrice(billItem.productPrice) }}
                      </td>
                      <td class="tdTextAlignPrice tdProductPrice">
                        <p
                          :class="[
                            `border-0 ${billItem.discountAmount || 'mb-0'}`,
                          ]"
                          title="C.khấu sản phẩm"
                          v-b-tooltip.hover
                        >
                          <i
                            v-show="billItem.discountAmount"
                            class="fa fa-arrow-down icon-color"
                          ></i>

                          <span class="font-weight-bold ml-2">{{
                            convertPrice(billItem.discountAmount)
                          }}</span>
                        </p>
                      </td>
                      <td class="tdTextAlignPrice tdProductPrice">
                        <p
                          v-b-tooltip.hover.bottom
                          title="C.khấu phân bổ"
                          :class="[
                            `border-0 ${
                              billItem.allotmentDiscountAmount || 'mb-0'
                            }`,
                          ]"
                        >
                          <i
                            v-show="billItem.allotmentDiscountAmount"
                            class="fa fa-arrow-down icon-color"
                          ></i>

                          <span class="font-weight-bold ml-2">{{
                            convertPrice(billItem.allotmentDiscountAmount)
                          }}</span>
                        </p>
                      </td>
                      <td class="tdTextAlignPrice tdQuantity">
                        <span
                          v-if="billItem.vatAmount !== null"
                          class="font-weight-bold"
                          v-text="
                            billItem.vatAmount !== -1
                              ? `${billItem.vatAmount}%`
                              : 'KCT'
                          "
                        ></span>
                      </td>
                      <td class="tdTextAlignPrice tdDiscountItem">
                        <p class="text-success font-weight-bolder mb-0">
                          {{ convertPrice(billItem.totalAmount) }}
                        </p>
                      </td>
                      <td
                        class="tdTextAlign tdNote"
                        :rowspan="bill.details.length"
                        v-if="j === 0"
                        @mouseover="hoverPrice = true"
                        @mouseleave="hoverPrice = false"
                      >
                        <p
                          class="mb-0"
                          :title="bill.billVatNote"
                          v-b-tooltip.hover
                        >
                          {{ truncateText(bill.billVatNote) }}
                          <i
                            class="fas fa-edit cursor-pointer mt-2"
                            @click="showModalNoteUpdate(bill)"
                          ></i>
                        </p>
                      </td>
                      <td
                        class="tdTextAlign tdStatus text-center wrapword"
                        :rowspan="bill.details.length"
                        v-if="j === 0"
                        @mouseover="hoverPrice = true"
                        @mouseleave="hoverPrice = false"
                      >
                        <span
                          style="width: max-content"
                          v-text="bill.partnerStatusName"
                          :class="bindClassNameStatus(bill.partnerStatus)"
                        ></span>
                      </td>
                      <td
                        :rowspan="bill.details.length"
                        v-if="j === 0"
                        class="tdTextAlign"
                      >
                        <div class="d-flex justify-content-center">
                          <b-dropdown
                            size="sm"
                            id="dropdown-left"
                            no-caret
                            right
                          >
                            <template slot="button-content">
                              <i
                                style="font-size: 1rem; padding-right: 0px"
                                class="flaticon2-settings"
                              ></i>
                            </template>
                            <b-overlay
                              v-if="
                                !bill.isDisable &&
                                checkPermission('VAT_INVOICE_EXPORT_MIFI') &&
                                  (checkPermission('VAT_INVOICE_EXPORT_MIFI_EXCEED_EXPIRED') || !bill.isBillVatExpired)
                              "
                              :show="isExporting"
                              rounded
                              class="d-inline-block mr-3"
                              variant="secondary"
                            >
                              <template #overlay>
                                <div class="d-flex justify-content-center">
                                  <strong>Đang xử lí...</strong>
                                  <b-spinner
                                    class="ml-auto"
                                    small
                                  ></b-spinner>
                                </div>
                              </template>
                              <b-dropdown-item
                                v-if="
                                  !bill.isDisable &&
                                  checkPermission('VAT_INVOICE_EXPORT_MIFI') &&
                                  (checkPermission('VAT_INVOICE_EXPORT_MIFI_EXCEED_EXPIRED') || !bill.isBillVatExpired)
                                "
                                @click="releaseVatInvoice(bill)"
                              >
                                <span style="font-size: 12px">
                                  <i
                                    style="font-size: 1rem"
                                    class="fas fa-award text-success"
                                  ></i>
                                  &nbsp; Đẩy hóa đơn đỏ sang Mifi
                                </span>
                              </b-dropdown-item>
                            </b-overlay>
                            <b-dropdown-item @click="uploadVatInvoice(bill.id)">
                              <span style="font-size: 12px">
                                <i
                                  style="font-size: 1rem"
                                  class="fas fa-upload text-primary"
                                ></i>
                                &nbsp; Tải chứng từ
                              </span>
                            </b-dropdown-item>
                            <b-dropdown-item
                              @click="deleteVatInvoice(bill.id)"
                              v-if="checkPermission('VAT_INVOICE_DELETE')"
                            >
                              <span style="font-size: 12px">
                                <i
                                  style="font-size: 1rem"
                                  class="fas fa-trash text-danger"
                                ></i>
                                &nbsp; Xóa hóa đơn
                              </span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col>
                <p class="mt-3 text-dark font-weight-bolder">
                  Tổng số :
                  {{ convertPrice(totalItems) }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="custom-pagination"
                  v-if="numberOfPage >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="getBillVatInvoices"
                  align="right"
                  first-class="page-item-first btn btn-icon mr-1 btn-sm my-1"
                  prev-class="page-item-prev btn btn-icon mr-1 btn-sm my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 "
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-1 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
    <VatInvoiceExcel :apiParams="apiParams" />
    <b-modal
      id="md-upload-vat-invoice"
      hide-footer
      :title="'Tải chứng từ cho hoá đơn đỏ: #' + vatInvoiceUpload.id"
      size="lg"
    >
      <b-container class="bv-example-row">
        <b-row>
          <div class="w-100">
            <UploadFile
              :id="vatInvoiceUpload.id"
              :entity="UPLOAD_ENTITY.BILLS_INVOICE"
            />
          </div>
        </b-row>
      </b-container>
    </b-modal>
    <b-modal
      id="md-update-vat-invoice"
      hide-footer
      :title="'Cập nhật: #' + vatInvoiceForm.id"
    >
      <b-row class="mb-5">
        <b-col cols="12">
          <label>Ghi chú hoá đơn đỏ</label>
          <b-form-textarea
            v-model="vatInvoiceForm.billVatNote"
            placeholder="Nhập ghi chú..."
            rows="5"
            max-rows="6"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-button
            style="fontweight: 600; width: 70px"
            variant="primary"
            size="sm"
            @click="updateNote"
          >Lưu</b-button>
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            @click="$bvModal.hide('md-update-vat-invoice')"
          >Hủy</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <template>
      <BillVatInvoiceModal />
    </template>
  </div>
</template>

<script>
import jQuery from 'jquery';
import moment from 'moment';
import Swal from 'sweetalert2';

import ApiService from '@/core/services/api.service';
import { EventBus } from '@/core/services/event-bus';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';

import {
  convertPrice,
  makeToastFaile,
  makeToastSuccess,
  truncateText,
} from '@/utils/common';
import { UPLOAD_ENTITY, VAT_SYNC_STATUS } from '@/utils/enum';
import localData from '@/utils/saveDataToLocal';
import { v4 as uuidv4 } from 'uuid';

const BillVatInvoiceModal = () =>
  import(
    /* webpackPrefetch: true */ '@/view/components/bills/BillVatInvoiceModal.vue'
  );
const VatInvoiceExcel = () =>
  import(
    /* webpackPrefetch: true */ '@/view/components/vat-invoice/VatInvoiceExcelV2.vue'
  );
const VatInvoiceHeader = () =>
  import(
    /* webpackPrefetch: true */ '@/view/components/vat-invoice/VatInvoiceHeader.vue'
  );
const KTCodePreview = () =>
  import(/* webpackPrefetch: true */ '@/view/content/CodePreview.vue');
const UploadFile = () =>
  import(/* webpackPrefetch: true */ '@/view/modules/upload/Upload');

export default {
  data() {
    return {
      UPLOAD_ENTITY,
      apiParams: {
        storeIds: [],
        billNumber: null,
        fromCreatedDate: null,
        toCreatedDate: null,
        fromUpdatedDate: moment().subtract(15, 'days').format('DD/MM/YYYY'),
        toUpdatedDate: moment().format('DD/MM/YYYY'),
        partnerStatus: null,
        billVatName: null,
        email: null,
        taxCode: null,
        billVatNote: null,
        billVatType: null,
      },
      title: '',
      vatInvoices: [],
      numberOfPage: 1,
      totalItems: 0,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      onLoading: false,
      hoverTable: {
        dataId: null,
      },
      vatInvoiceForm: {
        id: null,
        billVatStatus: null,
        billVatNote: null,
      },
      vatInvoiceFormMode: 'status',
      vatInvoiceUpload: {
        id: null,
      },
      isExporting: false,
      idempotenceKey: null,
    };
  },
  components: {
    KTCodePreview,
    VatInvoiceHeader,
    VatInvoiceExcel,
    UploadFile,
    BillVatInvoiceModal,
  },

  mounted() {
    this.title = 'Danh sách hoá đơn đỏ';
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn', route: '/bills' },
      { title: this.title },
    ]);
  },
  created() {
    this.getBillVatInvoices();
  },
  methods: {
    convertPrice,
    truncateText,
    makeToastFaile,
    makeToastSuccess,
    async updateNote() {
      const payload = { ...this.vatInvoiceForm };
      const url = `vat-bills/note`;
      const method = 'put';

      try {
        const res = await ApiService[method](url, { ...payload });
        this.makeToastSuccess(res.data.message);
        this.$bvModal.hide('md-update-vat-invoice');
        this.getBillVatInvoices();
      } catch (error) {
        this.makeToastFaile(error.response.data.message || 'Lỗi');
      }
    },
    showConfirmNotify({
      title,
      text,
      icon = 'warning',
      actionName,
      confirmButtonColor = 'rgb(221, 51, 51)',
    }) {
      return Swal.fire({
        title,
        text,
        icon,
        confirmButtonColor,
        showCancelButton: true,
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: actionName,
        reverseButtons: true,
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
      });
    },
    async deleteVatInvoice(id) {
      const content = {
        title: 'Xóa hóa đơn!',
        text: 'Bạn có chắc muốn hóa đơn này không ?',
        actionName: 'Xóa',
      };
      const result = await this.showConfirmNotify(content);
      if (!result.value) return;

      const res = await ApiService.delete(`vat-bills/${id}`);
      const { status, message } = res.data;
      if (status !== 1) {
        return this.makeToastFaile(message);
      }
      this.makeToastSuccess(message);
      this.getBillVatInvoices();
    },
    async getBillVatInvoices() {
      if (this.onLoading) return;

      this.onLoading = true;
      this.vatInvoices = [];

      const params = this.getParamFilters();
      const url = 'vat-bills';
      const method = 'query';

      try {
        const res = await ApiService[method](url, { params });
        const { dataset, totalPage, totalRow } = res.data.data;

        this.totalItems = totalRow;
        this.numberOfPage = totalPage;

        this.vatInvoices = dataset;
      } catch (error) {
        if (error.response) {
          this.makeToastFaile(
            error.response.data ? error.response.data.message : 'Lỗi',
          );
        } else {
          this.makeToastFaile(error.message || 'Lỗi');
        }
      } finally {
        this.onLoading = false;
      }
    },
    async releaseVatInvoice(bill) {
      const content = {
        title: `Phát hành hóa đơn qua Mifi`,
        text: `Bạn có chắc phát hành hóa đơn #${bill.billNumber} không ?`,
        actionName: 'Xác nhận',
        confirmButtonColor: '#3699FF',
      };
      const result = await this.showConfirmNotify(content);
      if (!result.value) return;

      if (this.isExporting) return;
      this.isExporting = true;

      if (!this.idempotenceKey) {
        this.idempotenceKey = uuidv4();
      }

      const id = bill.billNumber;
      const url = `vat-bills/${id}/export-bill-vat`;
      const method = 'post';

      try {
        const res = await ApiService[method](url, {}, this.idempotenceKey);
        const successMes = res.data.message;
        this.makeToastSuccess(`${successMes}`, 'success');
      } catch (error) {
        this.isExporting = false;
        if (error.response) {
          this.makeToastFaile(
            error.response.data
              ? error.response.data.message
              : 'Có vấn đề xảy ra hãy thử lại',
          );
        } else {
          this.makeToastFaile(error.message || 'Có vấn đề xảy ra hãy thử lại');
        }
      } finally {
        this.getBillVatInvoices().then(() => (this.isExporting = false));
      }
    },
    checkPermission(condition) {
      return localData.checkPermission(condition);
    },
    showModalNoteUpdate(data) {
      this.vatInvoiceForm = {
        id: data.id,
        billVatNote: data.billVatNote,
      };
      this.$bvModal.show('md-update-vat-invoice');
    },
    showModalCreateVatInvoice(bill, mode) {
      const updateVatInvoice = {
        bill,
        mode,
      };
      EventBus.$emit('popup-create-vat-invoice', updateVatInvoice);
    },
    navigateToDetailBill(billNumber) {
      this.$router.push({
        path: '/bills/update-bill',
        query: { id: billNumber },
      });
    },
    bindClassNameStatus(vatStatus) {
      const status = Number(vatStatus);
      const statusMap = {
        [VAT_SYNC_STATUS.NEW]: 'badge badge-warning text-white',
        [VAT_SYNC_STATUS.SENT_FAIL]: 'badge badge-danger',
        [VAT_SYNC_STATUS.PUBLIC]: 'badge badge-primary text-white',
        [VAT_SYNC_STATUS.SENT_SUCCESS]: 'badge badge-success text-white',
        [VAT_SYNC_STATUS.CANCELED]: 'badge badge-warning',
      };

      return statusMap[status] || '';
    },
    hoverBill(evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.addClass('tr-rowspan-hover');
    },
    outBill(evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.removeClass('tr-rowspan-hover');
    },
    hoverRow(evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      if (this.hoverTable.dataId !== dataId) {
        const rows = jQuery(`[data=${dataId}]`);
        rows.addClass('tr-rowspan-hover');
        this.hoverTable.dataId = dataId;
      }
    },
    outRow(evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.removeClass('tr-rowspan-hover');
      this.hoverTable.dataId = null;
    },
    convertStoreIds(storeIds) {
      if (!storeIds || !storeIds.length) return [];
      return storeIds.map((item) => item.id);
    },
    getParamFilters() {
      const newParams = Object.assign({}, this.apiParams);
      const DATE_FORMAT = 'DD/MM/YYYY';
      const DATE_DB_FORMAT = 'YYYY-MM-DD';

      const formattedFromCreatedDate =
        newParams.fromCreatedDate &&
        moment(newParams.fromCreatedDate, DATE_FORMAT).format(DATE_DB_FORMAT);
      const formattedToCreatedDate =
        newParams.toCreatedDate &&
        moment(newParams.toCreatedDate, DATE_FORMAT).format(DATE_DB_FORMAT);

      const formattedFromUpdatedDate =
        newParams.fromUpdatedDate &&
        moment(newParams.fromUpdatedDate, DATE_FORMAT).format(DATE_DB_FORMAT);
      const formattedToUpdatedDate =
        newParams.toUpdatedDate &&
        moment(newParams.toUpdatedDate, DATE_FORMAT).format(DATE_DB_FORMAT);

      const convertedStoreIds = this.convertStoreIds(newParams.storeIds);

      return {
        ...newParams,
        page: this.$route.query.page || 1,
        size: this.size || 10,
        fromCreatedDate: formattedFromCreatedDate,
        toCreatedDate: formattedToCreatedDate,
        fromUpdatedDate: formattedFromUpdatedDate,
        toUpdatedDate: formattedToUpdatedDate,
        storeIds: convertedStoreIds,
      };
    },
    onFilter() {
      this.getBillVatInvoices();
    },
    downloadExcel() {
      this.$bvModal.show('modal-vat-invoice-excel');
    },
    uploadVatInvoice(id) {
      this.vatInvoiceUpload = {
        id,
      };
      this.$bvModal.show('md-upload-vat-invoice');
    },
    isShownWarningExpiredBillVat(billVat) {
      return [VAT_SYNC_STATUS.NEW].includes(billVat.partnerStatus);
    },
  },
};
</script>
<style lang="scss" >
.custom-pagination {
  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
.vat-invoice {
  .table-bordered-list th {
    border: 0.5px solid #d2d4d6 !important;
    background-color: #e0e0e0;
  }

  .table-bordered-list td {
    border: 0.5px solid #f3f3f3;
    border-bottom: 0.5px solid #adadad;
  }
  .wrapword {
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap; /* Chrome & Safari */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: pre-wrap; /* CSS3 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    word-break: break-all;
    white-space: normal;
  }

  .table th,
  .table td {
    vertical-align: middle;
  }
  .tdCenter {
    vertical-align: left !important;
  }
  .tdTextAlign {
    vertical-align: middle;
  }
  .tdTextAlignPrice {
    text-align: right !important;
    vertical-align: middle;
  }

  .tdCode {
    width: 5%;
  }
  .tdStore {
    width: 6.8%;
  }
  .tdCustomer {
    width: 35%;
  }
  .tdProductName {
    width: 35%;
  }
  .tdProductPrice {
    width: 3%;
  }
  .tdQuantity {
    width: 3%;
  }
  .tdDiscountItem {
    width: 5%;
  }
  .tdTotalAmount {
    width: 8%;
  }
  .tdNote {
    width: 15%;
  }
  .tdStatus {
    width: 5%;
  }

  .icon-size {
    font-size: 1rem;
  }

  table tbody td {
    vertical-align: middle;
  }

  .date-style {
    display: flex;
    align-items: center;
  }

  .dashed {
    border: 1px dashed #464e5f;
    border-style: dashed none none;
  }
}
</style>
